/* webapp-totgresca/src/controller/controller.scss */

@import "../common.scss";

.forcedBackground {
  background-color: #067c7f;
}

/* Container for the entire app */
.container {
  display: flex;
  flex-direction: column;
  height: 1000vh; /*For browser that don't understand svh*/
  height: 100svh; /* Ensures the container takes the full viewport height */
  padding: 0; /* Deletes spacing*/
}

/* Styling for the title at the top of the page */
.title {
  color: #fcc723; /* Sets the title color */
  text-align: center;
  font-size: 48px;
  margin: 12px 0 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
}

/* Styling for the card that holds the draggable list */
.card {
  background-color: #067c7f;
  border: 2px solid #fcc723; /* Yellow border around the card */
  border-radius: 20px;
  overflow-y: auto; /* Allows for a vertical scrollbar */
  padding: 0 10px; /* Horizontal padding only */
  margin: 15px 0 20px; /* Adds spacing above and below the card */
  max-height: 80%; /* Limits the card height to allow space for title and buttons */
  width: 90%; /* Adjust the width as necessary */
  align-self: center; /* Only center the card horizontally */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for depth */
}

/* Styling for each draggable list item */
.draggableListItem {
  background-color: #fcc723; /* Yellow background for list items */
  border-radius: 10px; /* Rounded corners for list items */
  margin: 10px 0px; /* Spacing between list items */
  padding: 10px;
  cursor: grab; /* Cursor changes to 'grab' when hovering over list items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for depth */
}
/* Remove the top margin of the first list item */
.draggableListItem:first-child {
  margin-top: 3px;
}
/* Remove the bottom margin of the last list item */
.draggableListItem:last-child {
  margin-bottom: 3px;
}

/* Container for the buttons */
.buttonsContainer {
  display: flex;
  justify-content: center; /* Centers buttons horizontally */
  align-items: center; /* Aligns buttons vertically */
  padding-bottom: 15px; /* Adds padding at the bottom */
  width: 90%; /* Adjust the width as necessary */
  align-self: center; /* Only center the card horizontally */
}

/* Individual button container */
.buttonContainer {
  flex-grow: 1; /* Allows the button containers to grow and fill the space */
  margin: 0 5px; /* Reduced horizontal margin to allow more space for buttons */
}

/* Styling for the buttons at the bottom of the page */
.button {
  width: 100%; /* Makes the buttons expand to fill their container */
}

.button:hover {
  background-color: #000000; /* Background color on hover */
}

/* Specific styling for the 'IT to top' and 'actualitzar' buttons */
.button.IT,
.button.actualitzar {
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for depth */
}

/* Specific styling for the 'IT to top' button */
.button.IT {
  background-color: #583ec2;
}

/* Specific styling for the 'actualitzar' button */
.button.actualitzar {
  background-color: rgb(10, 64, 183);
}

/* Styling for the scrollbar within the card */
.card::-webkit-scrollbar {
  width: 6px; /* Sets the width of the scrollbar */
}

.card::-webkit-scrollbar-thumb {
  background: #ffedb67b; /* Sets the scrollbar color to dark grey */
  border-radius: 3px;
}

/* Responsive layout for larger screens */
@media (min-height: 1280px) {
  .container {
    align-items: center; /* Centers content horizontally in the container */
  }
}

/* Responsive font size for smaller screens */
@media (max-width: 440px) {
  .title {
    font-size: 11vw; /* Adjusts font size based on viewport width */
  }
}






