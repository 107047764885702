/* webapp-totgresca/src/main/main.scss */

  @import '../common.scss';

  .theme-engrescat {
    --background-color: rgb(
      10,
      212,
      131
    ); /* New background color for Engresca't */
    --text-color: #5c007a; /* New text color for Engresca't */
    --border-color: #5c007a; /* New border color for Engresca't */
  }

  .theme-comigenere {
    --background-color: #7f027f;
    --text-color: var(--default-text);
    --border-color: var(--default-border);
  }

  .theme-espanya {
    --background-color: var(--default-background);
    --text-color: #5c007a;
    --border-color: #5c007a;
  }

  h1,
  h2 {
    margin: 0; /* Removes default margin */
  }

  h1 {
    /*Títol "Reunió Telecogresca"*/
    padding-top: 0.3em; /* Adds some space above the text */
    font-size: 3.75rem;
    font-family: "URBANOBold", "HelveticaNeue", Helvetica, Arial, sans-serif;
  }

  h2 {
    /*Countdown*/
    padding: 0.2em 0;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .titleContainer {
    display: flex;
    justify-content: center; /* Align horizontal */
  }

  .countdownContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .eventCountdown {
    margin: 0.5rem 0;
  }

  .eventCountdown h2 {
    margin: 0;
    padding: 0;
  }

  .moveSectionButtonContainer {
    text-align: center;
    margin-top: 0.5rem; /* Adjusted top margin */
  }

  .moveSectionButton {
    min-width: 3.125rem;
    min-height: 3.125rem;
    background-color: #06387f;
    color: var(--text-color);
  }

  .moveSectionButton:hover {
    background-color: #0c3875b8; /* Background color on hover */
  }

  .sectionListContainer {
    display: grid;
    grid-template-columns: 26% auto 26%; /* Three columns layout */
    gap: 0rem; /* Gap between the columns */
    justify-items: center;
    align-items: start; /* Align items to the start of the container */
    padding: 0 2%;
  }

  .sectionListCard,
  .speakingSectionCard {
    margin: 1rem 0;
  }

  /* Common styles for all lists */
  .sectionListCard {
    text-align: center; /* Center text horizontally */
    background-color: rgba(0, 0, 0, 0.05); /* Background color */
    border: 2px solid var(--border-color); /* Yellow border */
    border-radius: 3.75rem;
    max-height: auto;
    min-height: 2.875rem; /* Set the minimum height to match your list item height */
    width: 90%;
    padding: 0.5rem;
    align-self: center top; /* Only center the card horizontally */
    align-items: start; /* Align items to start at the same height */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for depth */
  }

  /* Style for list items */
  .sectionListCard ul {
    list-style-type: none; /* Remove default list bullets */
    padding: 0; /* Reset default padding */
  }

  .sectionListCard li {
    padding: 0.25rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); /* Darker border */
  }

  .sectionListCard li:first-child {
    padding-top: 0px;
  }

  .sectionListCard li:last-child {
    padding-bottom: 0;
    border-bottom: none; /* Remove border from the last item */
  }

  .strikethrough {
    text-decoration: line-through; /* Crosses the done sections */
  }

  .middleContainer {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically if there's space */
    width: 100%;
  }

  /* Specific style for SpeakingSectionDisplay */
  .speakingSectionCard {
    font-size: 300%; /* Triple the size of the text */
    font-family: "URBANOBold", "HelveticaNeue", Helvetica, Arial, sans-serif;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px solid var(--border-color); /* Yellow border around the card */
    border-radius: 2.5rem;
    min-height: 5rem;
    max-height: auto; /* Allows the card to expand to fit the content */
    min-width: 90%;
    padding: 0 0.5rem; /* Horizontal padding only */
    padding-top: 0.5rem;
    align-self: center top; /* Only center the card horizontally */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for depth */
  }

  .timerContainer {
    font-size: 5rem; /* Adjust the size as needed */
    text-align: center; /* Center the timer text */
    margin: 0; /* Remove default margin */
    color: var(
      --text-color
    ); /* Default text color. Red is managed inside the Timer component */
  }

  .timerContainerRed {
    color: red; /* Change text color to red */
  }

  @keyframes blinker {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  .blinking {
    animation: blinker 2s linear 3; /* Blink three times with 1 second per cycle */
  }

  .invisible {
    visibility: hidden;
  }

  .image-hidden {
    display: none;
  }

  .image-espanya {
    background-image: url("./assets/espanya.png");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: 100% 100%; /* Stretch and deform the image to fit */
    background-position: center;
    z-index: -3; /* Ensures the image is behind other content */
    display: block;
  }

  .image-knife {
    background-image: url("./assets/knife.gif");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover; /* I'm using 'cover' to maintain aspect ratio */
    background-position: center;
    z-index: -2; /* Negative z-index to ensure it's behind other content */
  }

  .image-estrellitas {
    background-image: url("./assets/estrellitas.gif");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: min(
      100vw,
      100vh
    ); /* Maintain aspect ratio while being able to see the full image*/
    background-position: center;
    z-index: -2;
  }

  .image-flowers {
    background-image: url("./assets/flowers.gif");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; /* Prevent the image from repeating */
    z-index: -2;
  }

  .image-monkey {
    background-image: url("./assets/monkey.gif");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .image-obama {
    background-image: url("./assets/obamaMedal.png");
    position: fixed;
    top: 10vh;
    left: 10vw;
    width: 80vw;
    height: 80vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .image-ratatouille {
    background-image: url("./assets/Ratatouille.webp");
    position: fixed;
    top: 60vh;
    left: 60vw;
    width: 20vw;
    height: 40vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .image-unoReverse {
    background-image: url("./assets/unoReverse.gif");
    position: fixed;
    top: 60vh;
    left: 32vw;
    width: 36vw;
    height: 36vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .image-telecogresca {
    background-image: url("./assets/telecogresca.jpg");
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover; /* I'm using 'cover' to maintain aspect ratio */
    background-position: center;
    z-index: -2;
  }

  /* Button styles for info and config buttons */
  .btn-info,
  .btn-config,
  .btn-reset {
    padding: 0.6rem 1.2rem; /* Increased padding for larger buttons */
  }

  /* Container for the info and config buttons */
  .bottom-right-buttons-container {
    position: fixed;
    bottom: 0.6rem;
    right: 0.6rem;
    display: flex;
    gap: 0.6rem; /* Adds space between the buttons */
  }

  /* You can define :hover styles if you want specific hover effects */
  .btn-info:hover,
  .btn-config:hover,
  .btn-reset:hover {
    background-color: #e7e7e7;
  }

  .backdrop {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .modal {
    position: fixed;
    z-index: 10; /* Higher than the backdrop */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .info-modal .modal-title {
    font-size: 2.5rem;
    font-family: "URBANOBold", "HelveticaNeue", Helvetica, Arial, sans-serif;
    color: var(--default-text);
    text-align: center;
    margin-bottom: 1rem; /* Spacing below the title */
  }

  .keybinds-list {
    list-style-type: none; /* Remove default list bullets */
    padding: 0; /* Reset default padding */
    color: black; /* Text color for the list */
  }

  .keybinds-list li {
    padding: 0.25rem 0;
  }







