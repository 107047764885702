/* webapp-totgresca/common.scss */

/* Font Face */
@font-face {
  font-family: "URBANOBold";
  src: url(./assets/URBANOBold.ttf);
}

@font-face {
  font-family: "HelveticaNeue";
  src: url(./assets/HelveticaNeue.ttc);
}

:root {
  --default-background: #067c7f;
  --default-text: #fcc723;
  --default-border: #fcc723;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%; /* This sets the default font size to be 16px, which makes 1rem = 16px */
}

.theme-default {
  --background-color: var(--default-background);
  --text-color: var(--default-text);
  --border-color: var(--default-border);
}

/* Body Style */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-size: 1.1rem;
  font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
  background-color: var(--background-color);
  height: 100%;
  color: var(--text-color);
}






